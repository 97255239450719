.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: transparent;
  font-family: inherit;
  font-size: 18px;
  line-height: 1.33333333;
  font-weight: 600;
  cursor: pointer;
  color: #000;
  padding: 0 0 2px 0;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  background-size: 100% var(--generalBorderWidth);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #004093, #004093);
  transition: all 0.2s;
}
.button:hover,
.button:focus,
.unit--isAnchor:hover .button {
  background-size: 0 var(--generalBorderWidth);
}
.section--two .subcontent.subcontent--1 {
  width: calc(100% - (200px + 24px));
}
.section--two .subcontent.subcontent--2 {
  width: 200px;
}
.section--three .subcontent {
  width: calc(50% - 12px);
}
.area--one {
  width: calc(100% + 24px);
  margin-left: -12px;
}
.area--one .unit {
  width: calc(100% - 24px);
  margin-left: 12px;
  margin-right: 12px;
}
.area--one .unitOne--1-2 {
  width: calc(50% - 24px);
}
.area--one .unitFive {
  width: calc((100% / 3) - 24px);
}
.area--twoA {
  width: calc(100% + 24px);
  margin-left: -12px;
}
.area--twoA .unit {
  width: calc(100% - 24px);
  margin-left: 12px;
  margin-right: 12px;
}
.area--four {
  width: calc(100% + 24px);
  margin-left: -12px;
}
.area--four .unit {
  width: calc(50% - 24px);
  margin-left: 12px;
  margin-right: 12px;
}
.area--five {
  width: calc(100% + 24px);
  margin-left: -12px;
}
.area--five .unit {
  width: calc(100% - 24px);
  margin-left: 12px;
  margin-right: 12px;
}
.area--five .unitOne--1-3 {
  width: calc(50% - 24px);
}
/*# sourceMappingURL=./screen-medium.css.map */